import { useEffect, useRef } from "react";

function useThrottleFunction(value: VoidFunction, limit: number) {
  const lastRan = useRef(Date.now());

  useEffect(() => {
    const handler = setTimeout(() => {
      if (Date.now() - lastRan.current >= limit) {
        value();
        lastRan.current = Date.now();
      }
    }, limit - (Date.now() - lastRan.current));

    return () => {
      clearTimeout(handler);
    };
  }, [value, limit]);
}

export default useThrottleFunction;
