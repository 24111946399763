import { NavLink, Link } from "react-router-dom";
import React, { useContext } from "react";
import logo from "res/images/logo.jpg";
import logoText from "res/images/logotext.png";
import { ProfileContext } from "../ProfileContext";
import { SidebarContext } from "../SidebarContext";
import classes from "./TopBar.module.scss";
import { FaExternalLinkAlt } from "react-icons/fa";
import Button from "./Button";
import { useLocation } from "react-router";

export default function TopBar() {
  const Profile = useContext(ProfileContext);
  const Sidebar = useContext(SidebarContext);
  const location = useLocation();

  function toggleSidebar() {
    if (Sidebar) {
      Sidebar.toggle();
    }
  }

  function closeSidebar() {
    if (Sidebar) {
      Sidebar.close();
    }
  }

  const isLoggedIn = Profile && Profile.getters.isLoggedIn;

  function renderAuthLinks() {
    if (!Profile) {
      return null;
    }

    if (!isLoggedIn) {
      return (
        <>
          <li>
            <NavLink to="/join">Join</NavLink>
          </li>
          <li>
            <NavLink to="/login">Login</NavLink>
          </li>
        </>
      );
    }

    // Logged in

    return (
      <>
        {!Profile.getters.isSubscribed && (
          <li>
            <NavLink to="/join">Join</NavLink>
          </li>
        )}

        <li>
          <NavLink to="/members" onClick={closeSidebar}>
            Members
          </NavLink>
        </li>

        {Profile.getters.isAdmin && !location.pathname.includes("admin") && (
          <li>
            <NavLink to="/admin">Admin</NavLink>
          </li>
        )}
      </>
    );
  }

  function renderWarnings() {
    if (!Profile?.getters.isLoggedIn) {
      return null;
    }

    if (Profile?.getters.hasIncompletePayment) {
      return (
        <div className={classes.fixedWarningContainer}>
          Looks like you have an incomplete payment. Please check your emails.
        </div>
      );
    }

    if (!Profile?.getters.isSubscribed) {
      const subscriptionsArr = Profile.state.auth?.user.subscriptions;
      if (
        subscriptionsArr &&
        subscriptionsArr.length > 0 &&
        subscriptionsArr[subscriptionsArr.length - 1].stripe_status === "incomplete"
      ) {
        return (
          <div className={classes.fixedWarningContainer}>
            Your subscription may still be processing on our end. If you still see this message in an hour, please visit
            our contact page and send us a message.
          </div>
        );
      }
      return (
        <div className={classes.fixedWarningContainer}>
          Your subscription has expired. Head over <NavLink to="/join"> the join page </NavLink> to resubscribe
        </div>
      );
    }
    return null;
  }

  return (
    <>
      {renderWarnings()}
      <div className={classes.container}>
        <header className={classes.header}>
          <div className={classes.headerContent}>
            {isLoggedIn && (
              <div className={classes.avatarContainer} onClick={toggleSidebar}>
                <img alt="logo" className={classes.avatar} src={logo} />
              </div>
            )}
            <div className={classes.headingContainer}>
              <Link to="/">
                <img alt="logo" src={logoText} />
              </Link>
            </div>
            <Button
              use="a"
              variant="pill"
              target="_blank"
              rel="nofollow"
              href="https://shop.nuancebro.com"
              className={classes.shopLink}
            >
              <span>
                <FaExternalLinkAlt />
                Shop
              </span>
            </Button>
            <nav className={classes.nav}>
              <ul className={classes.navList}>
                {!isLoggedIn && (
                  <li className={classes.homeLink}>
                    <NavLink to="/">Home</NavLink>
                  </li>
                )}
                <li>
                  <NavLink to="/about" onClick={closeSidebar}>
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" onClick={closeSidebar}>
                    Contact
                  </NavLink>
                </li>
                {renderAuthLinks()}
              </ul>
            </nav>
          </div>
        </header>
      </div>
    </>
  );
}
