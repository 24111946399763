import { push } from "utils/routerHistory";
import React, { Suspense, useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { MdDashboard, MdFolder, MdSupervisorAccount, MdMonetizationOn, MdPerson } from "react-icons/md";
import ErrorBoundary from "components/ErrorBoundary";
import PageLoader from "components/PageLoader";
import { ProfileContext } from "components/ProfileContext";
import { SidebarProvider } from "components/SidebarContext";
import Footer from "components/ui/Footer";
import SideBar from "components/ui/SideBar";
import TopBar from "components/ui/TopBar";
import classes from "./Admin.module.scss";

const items = [
  {
    link: "/admin",
    icon: MdDashboard,
    text: "Dashboard",
  },
  {
    link: "/admin/posts",
    icon: MdFolder,
    text: "Posts",
  },
  {
    link: "/admin/users",
    icon: MdSupervisorAccount,
    text: "User Accounts",
  },
  {
    link: "/admin/plans",
    icon: MdMonetizationOn,
    text: "Pricing Plans",
  },
  {
    link: "/members/user/profile",
    icon: MdPerson,
    text: "Profile",
  },
];

export default function AdminLayout(props: any) {
  const Profile = useContext(ProfileContext);

  useEffect(() => {
    if (!Profile || !Profile.getters.isAdmin) {
      push("/");
    }
  }, [Profile]);

  return (
    <>
      <SidebarProvider>
        <TopBar />
        <SideBar items={items} placement="left" alwaysOpenDesktop={true} />
      </SidebarProvider>

      <div className={classes.container}>
        <ErrorBoundary key={1}>
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>

        <Footer />
      </div>
    </>
  );
}
