import React, { useState, useMemo } from "react";
import Button from "components/ui/Button";
import Card from "components/ui/Card";
import { Plan } from "typings/types";
import classes from "./PricingTable.module.scss";
import classnames from "classnames";
import ErrorMessage from "components/ui/ErrorMessage";
import Markdown from "markdown-to-jsx";
import { useStoreActions } from "store";
import usePromise from "hooks/usePromise";
import Skeleton from "components/Skeleton/Skeleton";

interface Props {
  onSelectPlan(plan: Plan): void;
  expanded?: boolean;
  className?: string;
  /** hides a specific plan id */
  filterPlanId?: string;
}

export default function PricingTable(props: Props) {
  const [seeAllPlans, setSeeAllPlans] = useState(props.expanded);
  // @ts-ignore
  const getPlans: () => Promise<Plan[]> = useStoreActions(actions => actions.plans.getPlans);
  const [payload, error] = usePromise(getPlans, []);
  const plans = useMemo(() => {
    if (seeAllPlans || !payload) {
      return payload;
    }
    const arr: Plan[] = [];
    for (let i = 0; i < 3; i += 1) {
      arr.push(payload?.[i]);
    }
    return arr;
  }, [payload, seeAllPlans]);

  const filteredPlans = useMemo(() => {
    if (!plans || !props.filterPlanId) {
      return plans;
    }
    return plans.filter(plan => plan.stripe_plan !== props.filterPlanId);
  }, [plans, props.filterPlanId]);

  if (error) {
    return (
      <>
        <ErrorMessage title="Error" message={error.message} />
      </>
    );
  }

  const containerClassName = classnames(classes.planListContainer, props.className);

  if (!payload) {
    return (
      <div>
        <div className={containerClassName}>
          {Array(3)
            .fill({})
            .map((item, index) => (
              <Card key={index} className={classes.planContainer}>
                <Skeleton height="70px" />
                <Skeleton height="235px" />
                <Skeleton height="40px" />
              </Card>
            ))}
        </div>
        <div className={classes.seeAllPlansContainer}>
          <Button disabled>See all plans</Button>
        </div>
      </div>
    );
  }

  function onSelectPlan(e: React.MouseEvent<HTMLDivElement>) {
    const id = Number(e.currentTarget.getAttribute("data-id"));
    const foundPlan = payload!.find((plan: any) => plan.id === id);
    if (foundPlan) {
      props.onSelectPlan(foundPlan);
    }
  }

  return (
    <div>
      <div className={containerClassName}>
        {filteredPlans?.map((item: any) => (
          <Card key={item.id} className={classes.planContainer}>
            <div className={classes.planBasicDetailsContainer}>
              <h3 data-id={item.id}>{item.name}</h3>
              <h1 className={classes.priceText}>${item.cost}</h1>
              <span>per month</span>
            </div>
            <div className={classes.planExtendedDetailsContainer}>
              <>
                <Markdown>{item.description}</Markdown>
                <div className={classes.selectPlanButtonContainer}>
                  <Button variant="secondary" size="large" data-id={item.id} onClick={onSelectPlan}>
                    Select
                  </Button>
                </div>
              </>
            </div>
          </Card>
        ))}
      </div>
      {!seeAllPlans && (
        <div className={classes.seeAllPlansContainer}>
          <Button onClick={() => setSeeAllPlans(true)}>See all plans</Button>
        </div>
      )}
    </div>
  );
}
