import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import useScript from "hooks/useScript";
import { Plan } from "typings/types";
import CheckoutForm from "./CheckoutForm";
import { getStripeKey } from "utils/utils";

export default function StripeFormContainer(props: { activePlan: Plan }) {
  // This will throw back a promise that will defer rendering until the Stripe script is loaded
  useScript("https://js.stripe.com/v3/");

  const stripeKey = getStripeKey();

  return (
    <StripeProvider apiKey={stripeKey}>
      <Elements>
        <CheckoutForm activePlan={props.activePlan} />
      </Elements>
    </StripeProvider>
  );
}
