import classnames from "classnames";
import unescape from "lodash.unescape";
import React, { useEffect, useState } from "react";
import { IoMdPlay } from "react-icons/io";
import YouTube from "react-youtube";
import classes from "./YoutubePlaylist.module.scss";
import Skeleton from "./Skeleton/Skeleton";
import { getRandomArbitrary } from "utils/utils";
import { useStoreActions } from "store";
import usePromise from "hooks/usePromise";
import Button from "./ui/Button";
import { FaYoutube } from "react-icons/fa";

export default function YoutubePlaylist() {
  const getPlaylist = useStoreActions(actions => actions.youtubePlaylist.getPlaylist);
  // @ts-ignore
  const [payload, error, fetchState] = usePromise(getPlaylist, []) as any;

  const [state, setState] = useState<{ videoId: string; autoplay: 0 | 1 }>({ videoId: "", autoplay: 0 });

  useEffect(() => {
    if (payload) {
      setState(currentState => ({ ...currentState, videoId: payload[0].id.videoId }));
    }
  }, [payload]);

  function renderContent() {
    if (!fetchState || fetchState === "pending") {
      return <LoadingUI />;
    }

    if (error || !payload) {
      return null;
    }

    return (
      <>
        <YouTube
          videoId={state.videoId}
          containerClassName={classes.youtubeContainer}
          opts={{ width: "100%", height: "100%", playerVars: { autoplay: state.autoplay } }}
        />
        <div className={classes.videoFeedContainer}>
          {payload.map((video: any, i: any) => (
            <div
              className={classnames({
                [classes.videoItemContainer]: true,
                [classes.active]: state.videoId === video.id.videoId,
              })}
              key={video.id.videoId}
              data-id={video.id.videoId}
              onClick={() => setState({ autoplay: 1, videoId: video.id.videoId })}
            >
              <div className={classes.playlistItemNumber}>
                {state.videoId !== video.id.videoId ? i + 1 : <IoMdPlay />}
              </div>
              <img
                alt="thumbnail"
                className={classes.videoThumbnail}
                src={video.snippet.thumbnails.default.url}
                width={video.snippet.thumbnails.default.width}
                height={video.snippet.thumbnails.default.height}
              />

              <div className={classes.content}>
                <span className={classes.videoItemTitle}>{unescape(video.snippet.title)}</span>
                <span className={classes.videoItemDate}>{parseDate(video.snippet.publishedAt, true)}</span>
              </div>
            </div>
          ))}
          <div style={{ display: "flex", justifyContent: "center", margin: "2rem 0" }}>
            <Button
              use="a"
              target="_blank"
              variant="secondary"
              rel="nofollow"
              href="https://www.youtube.com/channel/UCQNqxS0Fu6CmzmVHSlnrShw/videos"
            >
              <span style={{ alignItems: "center", display: "flex" }}>
                <FaYoutube style={{ marginRight: 5 }} size={24} />
                All videos
              </span>
            </Button>
          </div>
        </div>
      </>
    );
  }

  return <div className={classes.container}>{renderContent()}</div>;
}

const LoadingUI = React.memo(() => (
  <>
    <Skeleton className={classes.youtubeContainer} />
    <div className={classes.videoFeedContainer}>
      <ListItemSkeleton active={true} />
      <ListItemSkeleton active={false} />
      <ListItemSkeleton active={false} />
    </div>
  </>
));

function ListItemSkeleton(props: { active: boolean }) {
  return (
    <div
      className={classnames({
        [classes.videoItemContainer]: true,
        [classes.active]: props.active,
      })}
      style={{ height: 90 }}
    >
      <div className={classes.playlistItemNumber}>
        <Skeleton height="15px" width="15px" />
      </div>
      <Skeleton containerClassName={classes.videoThumbnail} className={classes.videoThumbnail} height="100%" />

      <div className={classes.content}>
        <Skeleton containerClassName={classes.videoItemTitle} width={getRandomArbitrary(180, 280)} height={30} />
        <Skeleton containerClassName={classes.videoItemDate} width={140} height={20} />
      </div>
    </div>
  );
}

function parseDate(dateString: string, omitTime: boolean = false) {
  const date = Date.parse(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  if (!omitTime) {
    options.hour = "2-digit";
    options.minute = "2-digit";
  }
  return Intl.DateTimeFormat("en-US", options).format(date);
}
