import * as qs from "qs";
import React, { useContext, useReducer, useState } from "react";
import { push } from "utils/routerHistory";
import useThrottleFunction from "hooks/useThrottleFunction";
import { handleError, initialState as initialFormState, reducer as formReducer, formActions } from "utils/formReducer";
import { fetchApi, userExists } from "utils/utils";
import { ProfileContext } from "./ProfileContext";
import classes from "./SetUserDetailsModal.module.scss";
import Button from "./ui/Button";
import CircularProgress from "./ui/CircularProgress";
import FormErrors from "./ui/FormErrors/FormErrors";
import InputField from "./ui/InputField";
import ExtendedModal from "./ui/Modal/Extended";

// const validUsernameRegex = new RegExp(/^[a-zA-Z0-9_]{5,}[a-zA-Z]+[0-9]*$/g);
const usernameRegex = new RegExp(/[^a-zA-Z0-9-_]/g);

function SetUserDetailsModal() {
  const Profile = useContext(ProfileContext)!;
  const [open, setOpen] = useState(true);
  const [username, setUsername] = useState("");
  const [usernameTaken, setUsernameTaken] = useState(false);
  const [state, dispatch] = useReducer(formReducer, initialFormState);

  const isValidUsername = Boolean(username.length > 2 && username.length < 50);

  useThrottleFunction(async () => {
    console.log("check", username);
    if (isValidUsername) {
      setUsernameTaken(await userExists("username", username));
    }
  }, 2000);

  function closeModal() {
    setOpen(false);
  }

  function onChangeUsername(e: React.ChangeEvent<HTMLInputElement>) {
    const newUsername = e.target.value.replace(usernameRegex, "").substring(0, 50);
    setUsername(newUsername);
  }

  async function onSubmit() {
    dispatch(formActions.formSubmitStart());
    // Manually check if username is valid
    const userTaken = await userExists("username", username);

    if (userTaken) {
      dispatch(formActions.formError("Username taken"));
      setUsernameTaken(true);
      return;
    }

    // submit

    try {
      await fetchApi<{ success: boolean }>("/user/updateUsername", {
        method: "POST",
        body: qs.stringify({
          username,
        }),
      });

      // Update user profile
      Profile.actions.updateUser({
        ...Profile.state.auth!.user,
        username,
      });

      dispatch(
        formActions.formSubmitSuccess({
          message: "Successfully updated your username",
        }),
      );

      closeModal();
      push("/members/user/profile");

      // Update profile
    } catch (e) {
      handleError(dispatch, e, "Whoops! Looks like something went wrong. Please try again");
    }
  }

  return (
    <ExtendedModal
      title={`Welcome, ${Profile.state.auth?.user.name}`}
      isOpen={open}
      onClose={closeModal}
      sticky={true}
      actions={
        <Button
          disabled={!isValidUsername || usernameTaken || state.loading}
          className={state.loading && classes.buttonContainer}
          onClick={onSubmit}
        >
          {state.loading ? <CircularProgress className={classes.loadingSpinner} /> : "Submit"}
        </Button>
      }
    >
      <h3>To start, set your username</h3>

      <InputField
        name="username"
        type="text"
        errorText={usernameTaken ? "Username taken" : undefined}
        minLength={2}
        maxLength={50}
        required={true}
        onChange={onChangeUsername}
        style={{ fontSize: "1.3rem" }}
        value={username}
      />

      <FormErrors errorText={state.formErrorText} inputErrors={state.formInputErrors} />

      <small>This will be used to log in and post comments</small>
    </ExtendedModal>
  );
}

export default SetUserDetailsModal;
