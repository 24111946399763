import React from "react";
import { FormInputError } from "utils/formReducer";
import classes from "./FormErrors.module.scss";

interface Props {
  /** Comes from server */
  inputErrors: FormInputError | null;
  /** Client-side */
  errorText: string;
}

function FormErrors(props: Props) {
  return (
    <>
      {props.errorText && (
        <p className={classes.errorText}>{props.errorText}</p>
      )}
      {props.inputErrors &&
        Object.keys(props.inputErrors.errors).map(err => (
          <li className={classes.errorText} key={err}>
            {props.inputErrors!.errors[err]}
          </li>
        ))}
    </>
  );
}

export default FormErrors;
