import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

// @ts-ignore
history.listen(({ location }) => {
  if (gtag && process.env.NODE_ENV === "production") {
    gtag("config", "UA-141254875-1", { page_path: location.pathname });
  }
});

// We are exporting the navigate method to use it arbitrary in the app
// @see https://reach.tech/router/api/LocationProvider
export const { push } = history;
