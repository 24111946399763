import { Dispatch, ReducerAction } from "react";
import { toast, Position } from "react-toastify";
import { ActionType, action } from "typesafe-actions";

export interface State {
  loading: boolean;
  formInputErrors: FormInputError | null;
  formErrorText: string;
}

export const initialState: State = {
  loading: false,
  formInputErrors: null,
  formErrorText: "",
};

export interface FormInputError {
  message: string;
  errors: {
    [key: string]: string;
  };
}

export const formActions = {
  formSubmitStart() {
    return action("FORM_SUBMIT_START");
  },
  formError(errorMessage: string) {
    return action("FORM_ERROR", errorMessage);
  },
  formInputError(error: FormInputError) {
    return action("FORM_INPUT_ERROR", error);
  },
  formSubmitSuccess(obj?: { message: string; position?: Position[keyof Position] }) {
    return action("FORM_SUBMIT_SUCCESS", obj);
  },
};

type Actions = ActionType<typeof formActions>;

export function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case "FORM_SUBMIT_START":
      return {
        ...state,
        formErrorText: "",
        formInputErrors: null,
        loading: true,
      };
    case "FORM_ERROR":
      return {
        ...state,
        loading: false,
        formErrorText: action.payload,
      };
    case "FORM_INPUT_ERROR":
      return {
        ...state,
        loading: false,
        formInputErrors: action.payload,
      };
    case "FORM_SUBMIT_SUCCESS":
      if (action.payload) {
        toast.success(action.payload.message, {
          position: action.payload.position || toast.POSITION.TOP_RIGHT,
        });
      }
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export function handleError(
  dispatch: Dispatch<ReducerAction<typeof reducer>>,
  e: Error | FormInputError | null,
  fallbackMessage = "",
) {
  console.warn(e);
  // TODO EMAIL
  if (e && e.hasOwnProperty("errors")) {
    dispatch(formActions.formInputError(e as FormInputError));
  } else {
    dispatch(formActions.formError(fallbackMessage));
  }
}
