import { push } from "utils/routerHistory";
import * as qs from "qs";
import React, { Dispatch, ReducerAction, useReducer } from "react";
import useLocalStorage from "hooks/useLocalStorage";
import { ActionType, AuthPayload, SimpleAction, User } from "typings/types";
import { fetchApi, tryCatch, updateApiToken } from "utils/utils";
import SetUserDetailsModal from "./SetUserDetailsModal";
import useAsyncEffect from "hooks/useAsync";

interface ProfileState {
  loaded: boolean;
  auth?: AuthPayload;
  setUser?: React.Dispatch<any>;
  errors: string[];
  loggingIn?: boolean;
}

interface ContextProps {
  state: ProfileState;
  actions: ReturnType<typeof createActions>;
  getters: {
    isLoggedIn: boolean;
    isAdmin: boolean;
    isSubscribed: boolean;
    hasIncompletePayment: boolean;
  };
}

export const ProfileContext = React.createContext<ContextProps | null>(null);

interface Props {
  children: React.ReactNode;
}

type Actions =
  | SimpleAction<"LOGIN">
  | SimpleAction<"LOGOUT">
  | ActionType<"REHYDRATE", AuthPayload | undefined>
  | ActionType<"UPDATE_USER", User>
  | ActionType<"SYNC_USER", Omit<AuthPayload, "token">>
  | ActionType<"LOGIN_SUCCESS", AuthPayload>
  | ActionType<"LOGIN_FAIL", string[]>;

function createReducer(setAuth: (payload: AuthPayload | undefined) => void) {
  return function reducer(state: ProfileState, action: Actions): ProfileState {
    switch (action.type) {
      case "REHYDRATE":
        return {
          ...state,
          auth: action.payload,
          loaded: true,
        };
      case "LOGIN":
        return {
          ...state,
          errors: [],
          loggingIn: true,
        };
      case "SYNC_USER":
        if (!state.auth) {
          return state;
        }
        const newAuth: AuthPayload = {
          ...state.auth,
          ...action.payload,
        };
        setAuth(newAuth);
        return {
          ...state,
          auth: newAuth,
        };
      case "LOGIN_SUCCESS":
        // Update localstorage
        setAuth(action.payload);
        // Update auth token
        updateApiToken(action.payload.token);
        return {
          ...state,
          loggingIn: false,
          auth: action.payload,
        };
      case "LOGIN_FAIL":
        return {
          ...state,
          loggingIn: false,
          errors: [...action.payload],
        };
      case "LOGOUT":
        // Remove localstorage
        setAuth(undefined);
        // Remove api token
        updateApiToken();
        return {
          ...state,
          auth: undefined,
        };
      case "UPDATE_USER":
        if (!state.auth) {
          return state;
        }
        const auth = {
          ...state.auth,
          user: action.payload,
        };
        setAuth(auth);
        return {
          ...state,
          auth,
        };
      default:
        return state;
    }
  };
}

type DispatchProp = Dispatch<ReducerAction<ReturnType<typeof createReducer>>>;

function createActions(dispatch: DispatchProp) {
  return {
    async login(credentials: { login: string; password: string }) {
      dispatch({ type: "LOGIN" });

      // Log in request
      const [err, res] = await tryCatch(
        fetchApi<AuthPayload>("/user/login", {
          method: "POST",
          body: qs.stringify(credentials),
        }),
      );

      if (err || !res) {
        console.warn(err);
        dispatch({ type: "LOGIN_FAIL", payload: [err!.message] });
        return;
      }

      dispatch({ type: "LOGIN_SUCCESS", payload: res });
      push("/members");
    },
    /**
     * User will get redirected to "/" afterwards
     */
    logout() {
      dispatch({ type: "LOGOUT" });
    },

    updateUser(payload: User) {
      dispatch({ type: "UPDATE_USER", payload });
    },

    registerSuccess(payload: AuthPayload) {
      dispatch({ type: "LOGIN_SUCCESS", payload });
    },
  };
}

export default function ProfileProvider(props: Props) {
  const initialState: ProfileState = {
    errors: [],
    auth: undefined, // initialUser
    loaded: false,
  };

  const [auth, setAuth] = useLocalStorage<AuthPayload | undefined>("ProfileContext.auth", undefined, false);

  const [state, dispatch] = useReducer(createReducer(setAuth), initialState);

  useAsyncEffect(async () => {
    if (auth) {
      dispatch({ type: "REHYDRATE", payload: auth });

      // Refresh user details
      try {
        const payload = await fetchApi<Omit<AuthPayload, "token">>("/user/sync");
        // Update user (updateApiToken gets called again here)
        dispatch({ type: "SYNC_USER", payload });
      } catch (e) {
        // Refresh token expired, logout
        dispatch({ type: "LOGOUT" });
      }
    } else {
      dispatch({ type: "REHYDRATE", payload: undefined });
    }
  }, []);

  // Wait till localstorage loaded
  if (!state.loaded) {
    return null;
  }

  const isLoggedIn = Boolean(state.auth);

  const needsToSetUserDetails = isLoggedIn && Boolean(!state.auth?.user.username);

  const { login, updateUser, registerSuccess, logout } = createActions(dispatch);

  const isAdmin = Boolean(state.auth?.user.type === "admin");

  return (
    <ProfileContext.Provider
      value={{
        state,
        actions: {
          login,
          updateUser,
          registerSuccess,
          logout,
        },
        getters: {
          isLoggedIn: Boolean(state.auth),
          isAdmin,
          isSubscribed: isAdmin || (isLoggedIn && Boolean(state.auth?.isSubscribed)),
          hasIncompletePayment: isLoggedIn && !isAdmin && Boolean(state.auth?.hasIncompletePayment),
        },
      }}
    >
      {props.children}
      {needsToSetUserDetails && <SetUserDetailsModal />}
    </ProfileContext.Provider>
  );
}
