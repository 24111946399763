import classnames from "classnames";
import React from "react";
import CircularProgress from "../CircularProgress";
import styles from "./Button.module.scss";

interface Props {
  use?: string;
  variant?: "fixed" | "secondary" | "outline";
  color?: "danger";
  size?: "large" | "xl";
  className?: string;
  onClick?: (e: React.MouseEvent<any>) => void;
  onKeyDown?: (e: React.KeyboardEvent<any>) => void;
  tabIndex?: number;
  disabled?: boolean;
  style?: any;
  href?: string;
  type?: string;
  fitContent?: boolean;
}

export default React.forwardRef(
  (
    { use: T = "button", variant, size, fitContent, children, loading, disabled, color, ...props }: Props & any,
    ref,
  ) => {
    const style = classnames(
      styles.button,
      styles[size],
      styles[variant],
      {
        [styles.loading]: loading,
        [styles.colorDanger]: color === "danger",
        [styles.fitContent]: fitContent,
      },
      props.className,
    );

    function renderContent() {
      if (loading) {
        return <CircularProgress />;
      }

      if (typeof children === "string") {
        return <span>{children}</span>;
      }

      return children;
    }
    return (
      <T {...props} ref={ref} className={style} disabled={disabled || loading}>
        {renderContent()}
      </T>
    );
  },
);
