import * as qs from "qs";
import { SERVER_API_URL } from "../config";
import TimeAgo from "javascript-time-ago";

// Load locale-specific relative date/time formatting rules.
import en from "javascript-time-ago/locale/en";

// Add locale-specific relative date/time formatting rules.
TimeAgo.addLocale(en);

const headers: { [key: string]: string } = {
  Accept: "application/json",
  "Content-Type": "application/x-www-form-urlencoded",
};

let apiToken: string | undefined;

let isAlreadyFetchingAccessToken = false;

(() => {
  const token = localStorage.getItem("token");
  if (token) {
    updateApiToken(token, false);
  }
})();

export function updateApiToken(token?: string, updateStorage = true) {
  apiToken = token;
  if (token) {
    if (updateStorage) {
      localStorage.setItem("token", token);
    }
    headers.Authorization = `Bearer ${token}`;
  } else {
    delete headers.Authorization;
  }
}

export async function refreshApiToken() {
  if (!apiToken || isAlreadyFetchingAccessToken) {
    return;
  }

  isAlreadyFetchingAccessToken = true;

  // Fetch
  const res = await fetch(`${SERVER_API_URL}/user/refreshToken?token=${apiToken}`);

  isAlreadyFetchingAccessToken = false;

  // Update token headers
  if (res.ok) {
    const jsonResponse: { token: string } = await res.json();
    updateApiToken(jsonResponse.token);
    onAccessTokenFetched();
  } else {
    // reset subscribers
    subscribers = [];

    throw new Error("Something went wrong. Please try logging out and back in");
  }
}

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers: Array<() => typeof fetchApi> = [];

function onAccessTokenFetched() {
  console.log("[onAccessTokenFetched]", subscribers);
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach(callback => callback());
  subscribers = [];
}

function addSubscriber(callback: () => typeof fetchApi) {
  console.log("add subscriber", callback);
  subscribers.push(callback);
}

export async function fetchApi<T>(url: string, init?: RequestInit): Promise<T> {
  if (isAlreadyFetchingAccessToken) {
    return new Promise(resolve => {
      // @ts-ignore
      addSubscriber(() => fetchApi(url, init).then(resolve));
    });
  }

  const params = {
    ...init,
    headers,
  };

  const res = await fetch(`${SERVER_API_URL}${url}`, params);

  // Get JSON response
  const jsonResponse = await res.json();

  if (!res.ok) {
    switch (res.status) {
      case 400:
      case 401: {
        const hasSessionExpired = Boolean(jsonResponse.error && jsonResponse.error === "SESSION_EXPIRED");
        /** Comes from jwt-auth middleware */
        const hasTokenExpired = Boolean(jsonResponse.message && jsonResponse.message === "Token has expired");
        if (hasSessionExpired || hasTokenExpired) {
          // JWT needs to be refreshed
          await refreshApiToken();

          return fetchApi(url, init);
        } else {
          throw jsonResponse;
        }
      }
      case 500: {
        if (jsonResponse.message) {
          throw new Error(jsonResponse.message);
        } else {
          throw new Error("Internal server error");
        }
      }
      case 404:
        throw new Error("404 - Not found");
      default:
        throw jsonResponse;
    }
  }

  return jsonResponse;
}

export async function tryCatch<T>(promise: Promise<T | Error>): Promise<[null, T] | [Error]> {
  try {
    const data = await promise;
    if (data instanceof Error) {
      return [data];
    }
    return [null, data];
  } catch (err) {
    return [err];
  }
}

export async function userExists(prop: "username" | "email", value: string): Promise<boolean> {
  try {
    const query = qs.stringify({
      property: prop,
      value,
    });
    const res = await fetchApi<{ exists: boolean }>(`/user/exists?${query}`);
    return res.exists;
  } catch (e) {
    console.warn(e);
    return true;
  }
}

export function parseDate(dateString: string) {
  //  Safari & IE browsers do not support the date format “yyyy-mm-dd”
  const date = Date.parse(dateString.replace(/-/g, "/"));
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return Intl.DateTimeFormat("en-US", options).format(date);
}

export function parseTimeAgo(dateString: string): string {
  //  Safari & IE browsers do not support the date format “yyyy-mm-dd”
  const date = Date.parse(dateString.replace(/-/g, "/"));

  const timeAgo = new TimeAgo("en-US");

  return timeAgo.format(date, "twitter");
}

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function getStripeKey() {
  if (window.location.search.includes("test")) {
    return "pk_test_7nCY7pgWkFm7iSQgJJyRLTJg";
  }
  return "pk_live_V6vKhAqu7FNzDUuhnIrXPOuJ";
}

export function getUrlParam(param: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}
