import React from "react";
import ErrorMessage from "./ui/ErrorMessage";

interface Props {
  /** Required. When changed, React will replace the component with a new instance */
  key: number | string;
  FallbackElement?: React.ReactNode;
  onError?: (error: any, info: any) => void;
}

interface State {
  error: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  // [React lifecycle] Update state so the next render will show the fallback UI.
  static getDerivedStateFromError() {
    return { error: true };
  }

  state = {
    error: false
  };

  // [React lifecycle] a child component throws an error
  componentDidCatch(error: any, info: any) {
    // Notify parent component
    if (this.props.onError) {
      this.props.onError(error, info);
    }
  }

  render() {
    if (this.state.error) {
      return this.props.FallbackElement || <DefaultFallbackElement />;
    }

    return this.props.children;
  }
}

function DefaultFallbackElement() {
  return (
    <ErrorMessage
      title="Whoops!"
      message="There was a problem loading the page. Please try again"
      button={{
        title: "Reload",
        onClick: () => window.location.reload()
      }}
    />
  );
}
