import { Link } from "react-router-dom";
import { push } from "utils/routerHistory";
import Markdown from "markdown-to-jsx";
import React, { useContext, useState } from "react";
import { IoIosLock } from "react-icons/io";
import YouTube from "react-youtube";
import { ProfileContext } from "components/ProfileContext";
import { Post as PostType } from "typings/types";
import { parseTimeAgo } from "utils/utils";
import Button from "../Button";
import Card from "../Card";
import classes from "./Post.module.scss";
import PostControls from "./components/PostControls";

interface Props {
  data: PostType;
  disableLink?: boolean;
}

function PostImage(props: { source: string }) {
  return <div className={classes.postImageContainer} style={{ backgroundImage: `url(${props.source})` }} />;
}

export default function Post(props: Props) {
  const Profile = useContext(ProfileContext)!;
  const [deleted, setDeleted] = useState(false);

  const isLocked = Boolean(props.data.members_only && (!Profile.getters.isLoggedIn || !Profile.getters.isSubscribed));

  if (deleted) {
    return null;
  }

  return (
    <Card variant="nopadding" className={classes.container}>
      <PostMedia data={props.data} isLocked={isLocked} />
      <div className={classes.postDetailsContainer}>
        <PostInfo data={props.data} isLocked={isLocked} />
        <PostContent
          data={props.data}
          isLocked={isLocked}
          disableLink={props.disableLink || !Profile.getters.isLoggedIn}
        />
      </div>

      {props.data && <PostControls post={props.data} onDelete={() => setDeleted(true)} />}
    </Card>
  );
}

/*
<div className={classes.controls}>
  <div className={classes.icons}>
    <div title="Share">
      <IoMdShare />
    </div>
    <HeartButton />
  </div>
</div>
*/

interface PostMediaProps {
  isLocked: boolean;
  data: PostType;
}

function PostMedia(props: PostMediaProps) {
  function navigateToJoinPage() {
    push("/join");
  }

  const { featured_type: type, featured_content: content } = props.data;

  if (props.isLocked) {
    return (
      <div className={classes.subscribeContainer}>
        <IoIosLock size={40} />
        <h4>Unlock this post by subscribing to Nuance Bro</h4>
        <Button onClick={navigateToJoinPage}>Subscribe now</Button>
      </div>
    );
  }

  if (!type || type.length <= 0) {
    return null;
  }

  if (type === "image" && content) {
    return <PostImage source={content} />;
  }

  if (type === "youtube" && content) {
    return (
      <YouTube
        opts={{
          playerVars: {
            color: "white",
          },
        }}
        className={classes.videoContainer}
        videoId={content}
      />
    );
  }

  return null;
}

interface PostInfoProps {
  isLocked: boolean;
  data: PostType;
}

function PostInfo(props: PostInfoProps) {
  const formattedDate = parseTimeAgo(props.data.created_at);

  return (
    <div className={classes.smallInfoContainer}>
      <img src={props.data.user_avatar} style={{ borderRadius: "50%" }} alt={props.data.title} />
      <div className={classes.date}>
        <strong>{props.data.user_name}</strong> · {formattedDate}
      </div>
      {props.isLocked && (
        <div className={classes.lockedText}>
          <IoIosLock size={16} /> Locked
        </div>
      )}
    </div>
  );
}

interface PostContentProps {
  isLocked: boolean;
  disableLink?: boolean;
  data: PostType;
}

function PostContent(props: PostContentProps) {
  function renderTitle() {
    if (props.isLocked || props.disableLink) {
      return <h2>{props.data.title}</h2>;
    }

    return (
      <Link className={classes.title} to={`/members/post/${props.data.slug}`}>
        <h2>{props.data.title}</h2>
      </Link>
    );
  }

  return (
    <>
      <div className={classes.titleContainer}>{renderTitle()}</div>
      {props.data.body && (
        <div className={classes.body}>
          <Markdown>{props.data.body}</Markdown>
        </div>
      )}
    </>
  );
}
