import React from "react";
import banner from "./banner.webp";
import bannerJpg from "./banner.jpg";
import classes from "./HeaderBanner.module.scss";

export default function HeaderBanner() {
  return (
    <div>
      <picture className={classes.bannerImage}>
        <source type="image/webp" srcSet={banner} />
        <source type="image/jpeg" srcSet={bannerJpg} />

        <img src={bannerJpg} className={classes.bannerImage} alt="Banner image" />
      </picture>
    </div>
  );
}
