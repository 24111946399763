/**
 * Suspends rendering until a given script is loaded
 * @param src Script source
 */
export default function useScript(src: string, cb?: () => boolean) {
  // Check whether script is already rendered in DOM
  const includesScript = document.querySelector(`script[src="${src}"]`);
  if (includesScript) {
    return;
  }

  // Create element
  const head = document.getElementsByTagName("head")[0];
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = src;
  head.appendChild(script);

  // Throw promise -- will suspense rendering until script loads
  throw new Promise((resolve) => {
    script.addEventListener("load", onScriptLoad);

    // Remove the event listener subscription and resume rendering
    function onScriptLoad() {
      script.removeEventListener("load", onScriptLoad);
      if (cb && !cb()) {
        setTimeout(() => {
          onScriptLoad();
        }, 100);
      } else {
        // @ts-ignore
        resolve();
      }
    }
  });
}
