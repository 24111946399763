import classnames from "classnames";
import React from "react";
import classes from "./Card.module.scss";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  variant?: "nopadding";
  rounded?: boolean;
};

export default function Card({ variant, className, rounded, ...rest }: Props) {
  return (
    <div
      {...rest}
      className={classnames(classes.card, classes[variant || ""], rounded && classes.rounded, className)}
    />
  );
}
