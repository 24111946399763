import { push } from "utils/routerHistory";
import { Outlet } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { ProfileContext } from "components/ProfileContext";
import DefaultLayout from "./Default";

export default function MembersLayout() {
  const Profile = useContext(ProfileContext);

  useEffect(() => {
    if (!Profile || !Profile.getters.isLoggedIn) {
      push("/");
    }
  }, [Profile]);

  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
}
