import { useEffect, useReducer } from "react";

function resolvePromise<T>(promise: any): Promise<T> {
  if (typeof promise === "function") {
    return promise();
  }

  return promise;
}

const states = {
  pending: "pending",
  rejected: "rejected",
  resolved: "resolved",
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case states.pending:
      return {
        error: undefined,
        result: undefined,
        state: states.pending,
      };

    case states.resolved:
      return {
        error: undefined,
        result: action.payload,
        state: states.resolved,
      };

    case states.rejected:
      return {
        error: action.payload,
        result: undefined,
        state: states.rejected,
      };

    /* istanbul ignore next */
    default:
      return state;
  }
}

function usePromise<T>(
  promise: () => Promise<T>,
  inputs?: any,
): [T | undefined, Error | undefined, "pending" | "resolved" | "rejected"] {
  const [{ error, result, state }, dispatch] = useReducer(reducer, {
    error: undefined,
    result: undefined,
    state: states.pending,
  });

  useEffect(() => {
    const resolvedPromise = resolvePromise<T>(promise);

    if (!promise) {
      return;
    }

    let canceled = false;

    dispatch({ type: states.pending });

    resolvedPromise.then(
      result => {
        if (!canceled) {
          dispatch({
            payload: result,
            type: states.resolved,
          });
        }
      },
      error =>
        !canceled &&
        dispatch({
          payload: error,
          type: states.rejected,
        }),
    );

    return () => {
      canceled = true;
    };
  }, [promise]);

  return [result, error, state];
}

export default usePromise;
