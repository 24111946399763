import React from "react";
import Post from "../Post";
import PostSkeleton from "../Post/PostSkeleton";
import classes from "./FrontPagePosts.module.scss";
import usePromise from "hooks/usePromise";
import { useStoreActions } from "store";

interface Props {
  public?: boolean;
}

export default function FrontPagePosts(props: Props) {
  const getFrontPagePosts = useStoreActions(actions => actions.common.getFrontPagePosts);

  // @ts-ignore
  const [payload, error, state] = usePromise(getFrontPagePosts, []);

  if (error) {
    return <h1>Whoops! Something went wrong</h1>;
  }

  function renderContent() {
    if (state === "pending") {
      return Array(5)
        .fill({})
        .map((item, index) => <PostSkeleton key={index} />);
    }

    if (!payload || payload.data.length === 0) {
      return null;
    }

    // @ts-ignore
    return payload.data.map(post => <Post key={post.id} data={post} />);
  }

  return (
    <div className={classes.recentPostsContainer}>
      <h2 className={classes.recentPostsTitle}>Recent Posts</h2>
      {renderContent()}
    </div>
  );
}
