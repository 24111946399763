import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import useDarkMode from "use-dark-mode";
import FrontPagePosts from "components/ui/FrontPagePosts";
import YoutubePlaylist from "components/YoutubePlaylist";
import TwoColumnGrid from "../layouts/TwoColumnGrid";
import usePageTitle from "hooks/usePageTitle";
import SupportNuanceBro from "components/SupportNuanceBro";
import HeaderBanner from "components/ui/HeaderBanner/HeaderBanner";

export default function IndexPage() {
  usePageTitle("Home");
  const darkMode = useDarkMode(false);

  return (
    <div>
      <HeaderBanner />
      <div style={{ paddingTop: "0.5rem", justifyContent: "center", display: "flex" }}>
        <TwoColumnGrid>
          <div>
            <YoutubePlaylist />
            <FrontPagePosts />
          </div>
          <div>
            <SupportNuanceBro />
            <TwitterTimelineEmbed
              key={darkMode.value}
              sourceType="profile"
              screenName="nuancebro"
              theme={darkMode.value ? "dark" : "light"}
              options={{ width: "100%", height: 500 }}
            />
          </div>
        </TwoColumnGrid>
      </div>
    </div>
  );
}
