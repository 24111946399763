import { push } from "utils/routerHistory";
import React from "react";
import Button from "./Button";

export default function SubscribeButton() {
  function navigateToJoin() {
    push("/join");
  }
  return (
    <Button onClick={navigateToJoin} variant="secondary" size="large">
      Subscribe
    </Button>
  );
}
