import React, { useState } from "react";

export const SidebarContext = React.createContext<{
  isOpen: boolean;
  open: VoidFunction;
  close: VoidFunction;
  toggle: VoidFunction;
} | null>(null);

export function SidebarProvider(props: any) {
  const [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  function toggle() {
    setIsOpen(state => !state);
    // console.log("Sidebar: toggle");
  }

  return <SidebarContext.Provider value={{ isOpen, open, close, toggle }}>{props.children}</SidebarContext.Provider>;
}
