import { NavLink } from "react-router-dom";
import classnames from "classnames";
import React, { useContext } from "react";
import { IoMdHome, IoMdLogOut } from "react-icons/io";
// import { IconType } from "react-icons/src/iconBase";
import { ProfileContext } from "../ProfileContext";
import { SidebarContext } from "../SidebarContext";
import DarkModeToggle from "./DarkModeToggle";
import classes from "./SideBar.module.scss";

interface Props {
  placement?: "left" | "right";
  items: Array<{
    link: string;
    icon: any;
    text: string;
  }>;
  alwaysOpenDesktop?: boolean;
}

export default function SideBar(props: Props) {
  const context = useContext(SidebarContext)!;
  const Profile = useContext(ProfileContext)!;

  function signOut(e: React.MouseEvent<HTMLAnchorElement>) {
    // User will get redirected to "/" anyways
    e.preventDefault();
    // Close sidebar
    context.close();
    // Logout. Gets redirected to "/"
    Profile.actions.logout();
  }

  return (
    <>
      <aside
        className={classnames(classes.sidebar, {
          [classes.open]: Boolean(context && context.isOpen),
          [classes.right]: props.placement === "right",
          [classes.left]: props.placement === "left",
          [classes.alwaysOpenDesktop]: props.alwaysOpenDesktop,
        })}
      >
        <div className={classes.sidebarContainer}>
          <ul className={classes.nav}>
            <li className={classes.homeLink}>
              <NavLink to="/" onClick={context.close}>
                <IoMdHome />
                Home
              </NavLink>
            </li>
            <div>
              {props.items.map(item => (
                <li key={item.text}>
                  <NavLink to={item.link} onClick={context.close}>
                    <item.icon />
                    {item.text}
                  </NavLink>
                </li>
              ))}
            </div>

            <hr className={classes.divider} />
            <li className={classes.settingsContainer}>
              <span>Dark Mode</span>
              <DarkModeToggle />
            </li>

            <li className={classes.signOutListItem}>
              <a href="/" onClick={signOut}>
                <IoMdLogOut />
                Sign Out
              </a>
            </li>
          </ul>
        </div>
      </aside>

      {context && context.isOpen && (
        <div
          className={classnames(classes.sidebarOverlay, {
            [classes.alwaysOpenDesktop]: props.alwaysOpenDesktop,
          })}
          onClick={context.close}
        />
      )}
    </>
  );
}
