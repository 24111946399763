import React, { useContext, useState } from "react";
import { ProfileContext } from "components/ProfileContext";
import { Post as PostType } from "typings/types";
import { fetchApi } from "utils/utils";
import classes from "./PostControls.module.scss";
import ExtendedModal from "components/ui/Modal/Extended";
import Button from "components/ui/Button";
import { IoMdTrash } from "react-icons/io";

export default function PostControls(props: { post: PostType; onDelete: VoidFunction }) {
  const Profile = useContext(ProfileContext)!;

  async function deletePost() {
    await fetchApi(`/post/${props.post.slug}/delete`);
    props.onDelete();
  }

  const [modalOpen, setModalOpen] = useState(false);

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  return (
    <div className={classes.controls}>
      {Profile.getters.isAdmin && (
        <>
          <div className={classes.icons}>
            <div title="Delete" onClick={openModal}>
              <IoMdTrash />
            </div>
          </div>
          <ExtendedModal
            isOpen={modalOpen}
            title="Delete post?"
            onClose={closeModal}
            actions={
              <>
                <Button variant="primary" onClick={deletePost}>
                  Yes
                </Button>
                <Button variant="outline" onClick={closeModal}>
                  Cancel
                </Button>
              </>
            }
          >
            Are you sure that you want to delete this post?
          </ExtendedModal>
        </>
      )}
    </div>
  );
}
