import { useEffect } from "react";

export default function useAsyncEffect(
  effect: () => Promise<any>,
  inputs: any[] = []
) {
  useEffect(() => {
    effect();
    // eslint-disable-next-line
  }, inputs);
}
