import React from "react";
import classes from "./TwoColumnGrid.module.scss";

interface Props {
  children: React.ReactNode;
}

export default function TwoColumnGrid(props: Props) {
  return <div className={classes.grid}>{props.children}</div>;
}
