import React from "react";
import { render } from "react-dom";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const rootElement = document.getElementById("root") as HTMLDivElement;

render(<App />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
