import React from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import classes from "./Footer.module.scss";

export default function Footer() {
  return (
    <footer className={classes.footer}>
      <div className={classes.content}>
        <div>
          <h4>FOLLOW ME</h4>
          <div className={classes.socialMediaLinks}>
            <a href="https://www.youtube.com/channel/UCQNqxS0Fu6CmzmVHSlnrShw" title="YouTube">
              <FaYoutube /> YouTube
            </a>
            <a href="https://www.twitter.com/nuancebro" title="Twitter">
              <FaTwitter /> Twitter
            </a>
            <a href="https://www.facebook.com/thenuancebro" title="Facebook">
              <FaFacebook /> Facebook
            </a>
            <a href="https://www.instagram.com/nuancebro" title="Instagram">
              <FaInstagram /> Instagram
            </a>
          </div>
        </div>
        <div className={classes.copyright}>© 2020, Nuance Bro</div>
      </div>
    </footer>
  );
}
