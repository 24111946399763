import React from "react";
import YouTube from "react-youtube";
import Card from "components/ui/Card";
import classes from "./about.module.scss";
import usePageTitle from "hooks/usePageTitle";

interface Props {
  path?: string;
}

export default function About(props: Props) {
  usePageTitle("About");

  return (
    <div className={classes.container}>
      <h1>About</h1>
      <YouTube
        containerClassName={classes.videoContainer}
        className={classes.video}
        videoId="0ISbmbEd7lY"
      />
      <Card>
        <p>
          Welcome to the Nuance Bro website! Thank you so much for your
          consideration in supporting me and my channel. I decided to make this
          website so that viewers can support me directly. Your support allows
          me to continue doing my work without relying on the discretion of
          YouTube to monetize my videos.
        </p>
        <p>
          I have created an award tier system and have added{" "}
          <a href="https://discordapp.com">Discord</a> awards! If you don't know
          what Discord is it's an app that allows us to talk in real time
          (similar to programs of the past like Teamspeak and Ventrilo). I'm
          pretty much in the Nuance Bro Discord whenever I am on my computer so
          consider joining and be a part of the community.
        </p>
        <p>
          Anyways with your support here I hope to bring you more high quality
          content more frequently. I'm looking to expand Nuance Bro to be able
          to travel across the country covering events and possibly
          internationally should any important events be happening. So please
          consider supporting this channel and counter YouTube censorship!
        </p>
      </Card>
    </div>
  );
}
