import { PaginatedPayload, Post } from "typings/types";
import { Thunk, Action, action, thunk } from "easy-peasy";
import { fetchApi } from "utils/utils";

interface MembersPayload {
  hasJoinedDiscord: boolean;
  posts: PaginatedPayload<Post>;
}

export interface CommonModel {
  posts: PaginatedPayload<Post> | undefined;
  hasJoinedDiscord: boolean;
  loadedPage: number;
  hasSyncedMembers: boolean;
  getFrontPagePosts: Thunk<CommonModel, void, any, any, Promise<PaginatedPayload<Post>>>;
  getPost: Thunk<CommonModel, { slug: string }, any, any, Promise<Post>>;
  getMembers: Thunk<CommonModel, number, any, any, Promise<PaginatedPayload<Post>>>;
  setPosts: Action<CommonModel, PaginatedPayload<Post>>;
  updatePost: Action<CommonModel, Post>;
  deletePost: Action<CommonModel, { id: number }>;
  setMembers: Action<CommonModel, { res: MembersPayload; page: number }>;
}

const commonModel: CommonModel = {
  posts: undefined,
  loadedPage: 1,
  hasJoinedDiscord: false,
  hasSyncedMembers: false,
  setPosts: action((state, payload) => {
    state.posts = payload;
  }),
  updatePost: action((state, payload) => {
    if (state.posts) {
      const postIndex = state.posts.data.findIndex(post => post.id === payload.id);
      if (postIndex !== -1) {
        state.posts.data[postIndex] = payload;
      }
    }
  }),
  deletePost: action((state, payload) => {
    if (state.posts) {
      const postIndex = state.posts.data.findIndex(post => post.id === payload.id);
      if (postIndex !== -1) {
        state.posts.data.splice(postIndex, 1);
      }
    }
  }),
  setMembers: action((state, payload) => {
    state.loadedPage = payload.page;
    state.posts = payload.res.posts;
    state.hasJoinedDiscord = payload.res.hasJoinedDiscord;
    state.hasSyncedMembers = true;
  }),
  getFrontPagePosts: thunk(async (actions, payload, helpers) => {
    const currentPosts = helpers.getState().posts;
    if (currentPosts) {
      return currentPosts;
    }
    const posts = await fetchApi<PaginatedPayload<Post>>("/posts");
    actions.setPosts(posts);
    return posts;
  }),
  getPost: thunk(async (actions, payload, helpers) => {
    const currentPosts = helpers.getState().posts;
    if (currentPosts) {
      const foundPost = currentPosts.data.find(post => post.slug === payload.slug);
      if (foundPost) {
        return foundPost;
      }
    }
    return fetchApi<Post>(`/post/${payload.slug}`);
  }),
  getMembers: thunk(async (actions, payload, helpers) => {
    const currentState = helpers.getState();
    if (currentState.posts && currentState.loadedPage === payload) {
      // Posts may need to be reloaded (logged out)
      const hasFeaturedContent = currentState.posts.data.find(post => post.featured_content);
      if (hasFeaturedContent) {
        // Sync discord (don't block UI)
        if (!currentState.hasSyncedMembers) {
          fetchApi<MembersPayload>(`/members?page=${payload}`).then(res => {
            actions.setMembers({ res, page: payload });
          });
        }
        return currentState.posts;
      }
    }

    const res = await fetchApi<MembersPayload>(`/members?page=${payload}`);
    actions.setMembers({ res, page: payload });
    return res.posts;
  }),
};

export default commonModel;
