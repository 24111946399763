import classnames from "classnames";
import React from "react";
import styles from "./PageLoader.module.scss";
import CircularProgress from "./ui/CircularProgress";

interface Props {
  className?: string;
  height?: number;
}

export default function PageLoader({ className, height }: Props) {
  return (
    <div
      className={classnames(styles.container, className)}
      style={height ? { minHeight: height } : undefined}
    >
      <CircularProgress />
    </div>
  );
}
