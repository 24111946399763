import React from "react";
import classes from "./Skeleton.module.scss";
import classnames from "classnames";

type Props = {
  count?: number;
  width?: number | string;
  height?: number | string;
  circle?: boolean;
  Wrapper?: React.ComponentType<any>;
  containerClassName?: string;
} & React.HTMLAttributes<HTMLSpanElement>;

export default function Skeleton(props: Props) {
  const elements = [];
  const { count = 1, Wrapper } = props;

  for (let i = 0; i < count; i++) {
    let style: React.CSSProperties = {};
    if (props.width) {
      style.width = props.width;
    }
    if (props.height) {
      style.height = props.height;
    }
    if (props.width && props.height && props.circle) {
      style.borderRadius = "50%";
    }
    if (props.style) {
      style = {
        ...style,
        ...props.style,
      };
    }
    elements.push(
      <span key={i} className={classnames(classes.skeleton, props.className)} style={style}>
        &zwnj;
      </span>,
    );
  }
  return (
    <span className={props.containerClassName}>
      {Wrapper
        ? elements.map((element, i) => (
            <Wrapper key={i}>
              {element}
              &zwnj;
            </Wrapper>
          ))
        : elements}
    </span>
  );
}
