import { useEffect } from "react";

export default function usePageTitle(title: string) {
  useEffect(() => {
    if (!title) {
      window.document.title = "Nuance Bro";
    } else {
      window.document.title = `${title} - Nuance Bro`;
    }
  }, [title]);
}
