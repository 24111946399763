import { Link } from "react-router-dom";
import React, { useContext, useState } from "react";
import { ProfileContext } from "components/ProfileContext";
import Button from "components/ui/Button";
import Card from "components/ui/Card";
import InputField from "components/ui/InputField";
import classes from "./login.module.scss";
import usePageTitle from "hooks/usePageTitle";
import { FaLock } from "react-icons/fa";

export default function LoginPage() {
  usePageTitle("Login");
  const Profile = useContext(ProfileContext);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (Profile) {
      Profile.actions.login({ login, password });
    }
  }

  function updateLogin(e: React.ChangeEvent<HTMLInputElement>) {
    setLogin(e.currentTarget.value);
  }

  function updatePassword(e: React.ChangeEvent<HTMLInputElement>) {
    setPassword(e.currentTarget.value);
  }

  if (!Profile) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Card>
        <h1>Login</h1>

        {Profile.state.auth && (
          <div className={classes.warning}>
            You are already logged in as {Profile.state.auth.user.name}. <Link to="/members">Visit members area</Link>
          </div>
        )}

        {Profile.state.errors.length > 0 && (
          <div className={classes.warning}>
            {Profile.state.errors.map((error, i) => (
              <span key={`error.${i}`}>{error}</span>
            ))}
          </div>
        )}
        <form method="post" action="/user/login" onSubmit={submit}>
          <div>
            <InputField
              label="Username or Email"
              type="text"
              name="username"
              value={login}
              onChange={updateLogin}
              required={true}
              minLength={3}
              maxLength={255}
            />
            <InputField
              label="Password"
              type="password"
              name="password"
              value={password}
              required={true}
              minLength={3}
              maxLength={255}
              onChange={updatePassword}
            />
          </div>
          <div style={{ marginTop: "1rem" }}>
            <Button className={classes.loginButton} loading={Profile.state.loggingIn}>
              <span>Log In</span>
              <FaLock className={classes.padlock} color="#fff" />
            </Button>
          </div>
          <small className={classes.forgotPasswordLink}>
            <Link to="/forgot-password">Forgot your password?</Link>
          </small>
        </form>
      </Card>
    </div>
  );
}
