import { Thunk, Action, action, thunk } from "easy-peasy";
import { fetchApi } from "utils/utils";

interface VideoThumbnail {
  url: string;
  width: number;
  height: number;
}

interface Video {
  kind: string;
  etag: string;
  id: {
    kind: string;
    videoId: string;
  };
  snippet: {
    /** Date/time */
    publishedAt: string;
    /** Video title */
    title: string;
    /** Video description */
    description: string;
    thumbnails: {
      default: VideoThumbnail;
      medium: VideoThumbnail;
      high: VideoThumbnail;
    };
  };
}

export interface YoutubePlaylistModel {
  playlist: Video[];
  getPlaylist: Thunk<YoutubePlaylistModel, void, any, any, Promise<Video[]>>;
  setPlaylist: Action<YoutubePlaylistModel, Video[]>;
}

const youtubePlaylistModel: YoutubePlaylistModel = {
  playlist: [],
  setPlaylist: action((state, payload) => {
    state.playlist = payload;
  }),
  getPlaylist: thunk(async (actions, payload, helpers) => {
    const currentPlaylist = helpers.getState().playlist;
    if (currentPlaylist.length > 0) {
      return currentPlaylist;
    }
    const videos = await fetchApi<Video[]>("/get-latest-channel-videos");
    actions.setPlaylist(videos);
    return videos;
  }),
};

export default youtubePlaylistModel;
