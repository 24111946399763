import { Link } from "react-router-dom";
import throttle from "lodash.throttle";
import * as qs from "qs";
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { FaCheck, FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import {
  CardElement,
  injectStripe,
  ReactStripeElements,
} from "react-stripe-elements";
import useDarkMode from "use-dark-mode";
import { ProfileContext } from "components/ProfileContext";
import Button from "components/ui/Button";
import CircularProgress from "components/ui/CircularProgress";
import FormErrors from "components/ui/FormErrors/FormErrors";
import InputField from "components/ui/InputField";
import { Plan, AuthPayload } from "typings/types";
import { RegisterPayload } from "typings/types";
import {
  handleError,
  initialState as initialFormState,
  reducer as formReducer,
} from "utils/formReducer";
import {
  fetchApi,
  userExists,
  tryCatch,
  getUrlParam,
  parseDate,
} from "utils/utils";
import "./CheckoutForm.css";
import classes from "./CheckoutForm.module.scss";
import { push } from "utils/routerHistory";
import InputLabel from "components/ui/InputLabel";
import Card from "components/ui/Card";

type Props = ReactStripeElements.InjectedStripeProps & {
  activePlan: Plan;
};

function isValidEmail(email: string) {
  const emailReg = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  return emailReg.test(email);
}

function CheckoutForm(props: Props) {
  const Profile = useContext(ProfileContext)!;

  // Form values
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptTOS, setAcceptTOS] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  // Form state
  const [state, dispatch] = useReducer(formReducer, initialFormState);
  const [cardErrorKey, setCardErrorKey] = useState(0);

  const checkUniqueEmail = useMemo(
    () =>
      throttle(async (e: string) => {
        console.log("[checkUniqueEmail]", e);
        setEmailTaken(await userExists("email", e));
      }, 1500),
    []
  );

  /** User is subscribing */
  const darkMode = useDarkMode(false);

  // Re-check whether email is valid
  useEffect(() => {
    if (emailTaken && isValidEmail(email)) {
      checkUniqueEmail(email);
    }
  }, [emailTaken, checkUniqueEmail, email]);

  function toggleAcceptTOS() {
    setAcceptTOS((s) => !s);
  }

  function validateForm(): [boolean, string] {
    if (!acceptTOS) {
      return [false, "Please accept the terms and conditions"];
    }

    if (!Profile.getters.isLoggedIn) {
      if (!isValidEmail(email)) {
        return [false, "Invalid email"];
      }

      if (confirmPassword !== password) {
        return [false, "Passwords don't match"];
      }

      if (emailTaken) {
        return [false, "Email is taken"];
      }
    }

    return [true, ""];
  }

  async function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // Prevent possible double clicks
    if (state.loading) {
      return;
    }

    dispatch({ type: "FORM_SUBMIT_START" });

    // Basic form validation
    const [isValid, errorText] = validateForm();

    if (!isValid) {
      dispatch({ type: "FORM_ERROR", payload: errorText });
      return;
    }

    const cardElement = props.elements?.getElement("card");

    if (!cardElement) {
      dispatch({
        type: "FORM_ERROR",
        payload:
          "Couldn't find your card details. Please try refreshing the page",
      });
      return;
    }

    const paymentMethod = await props.stripe?.createPaymentMethod(
      "card",
      cardElement,
      {
        billing_details: {
          name: Profile.getters.isLoggedIn
            ? Profile.state.auth?.user.name
            : name,
        },
      }
    );

    // Inform the user if there was an error
    if (
      !paymentMethod ||
      paymentMethod.error ||
      !paymentMethod?.paymentMethod
    ) {
      if (paymentMethod?.error?.message) {
        dispatch({ type: "FORM_ERROR", payload: paymentMethod.error.message });
      } else {
        dispatch({
          type: "FORM_ERROR",
          payload:
            "There was an error processing your payment. Please contact support if this persists",
        });
      }
      setCardErrorKey((currentState) => currentState + 1);
      return;
    }

    const { body, url } = (() => {
      const baseParams = {
        stripePlanIdentifier: props.activePlan.stripe_plan,
        paymentMethodId: paymentMethod.paymentMethod.id,
        testKey: props.activePlan.is_test ? getUrlParam("test") : "",
      };

      if (Profile.getters.isLoggedIn) {
        return {
          body: qs.stringify(baseParams),
          url: "/user/createSubscription",
        };
      }
      return {
        body: qs.stringify({
          ...baseParams,
          name,
          email,
          password,
        }),
        url: "/user/register",
      };
    })();

    const [err, response] = await tryCatch(
      fetchApi<RegisterPayload>(url, {
        method: "POST",
        body,
      })
    );

    if (err || !response) {
      handleError(
        dispatch,
        err,
        "Whoops! Looks like something went wrong when completing your subscription. Please contact support."
      );
      return;
    }

    switch (response.status) {
      case "fail":
        dispatch({
          type: "FORM_ERROR",
          payload: response.data,
        });
        return;
      case "PaymentActionRequired":
        await handleCardConfirmation(response.data.clientSecret);
        return;
      case "success":
        handleSuccess(response.data);
        break;
      default:
        break;
    }
  }

  async function handleCardConfirmation(clientSecret: string) {
    const result = await props.stripe!.confirmCardPayment(clientSecret);

    if (result.error) {
      dispatch({
        type: "FORM_ERROR",
        payload:
          result.error.message ||
          "Whoops! Looks like something went wrong when completing your subscription. Please contact support.",
      });
      return;
    }

    // The payment has succeeded. Get user session from API & verify payment

    const [err, confirmPaymentResponse] = await tryCatch(
      fetchApi<RegisterPayload>("/user/confirm-payment", {
        method: "POST",
        body: qs.stringify({
          paymentIntentId: result.paymentIntent?.id,
          testKey: props.activePlan.is_test ? getUrlParam("test") : "",
        }),
      })
    );

    if (err || !confirmPaymentResponse) {
      dispatch({
        type: "FORM_ERROR",
        payload:
          err?.message ||
          "Whoops! There seems to be a problem confirming your payment. Please contact support.",
      });
      return;
    }

    if (confirmPaymentResponse.status !== "success") {
      dispatch({
        type: "FORM_ERROR",
        payload: String(confirmPaymentResponse.data),
      });
      return;
    }
    handleSuccess(confirmPaymentResponse.data);
  }

  function handleSuccess(data: AuthPayload) {
    Profile.actions.registerSuccess(data);
    dispatch({
      type: "FORM_SUBMIT_SUCCESS",
      payload: {
        message:
          "Awesome! We've sent you an email confirming your subscription",
      },
    });

    push("/members");
  }

  function onChangeName(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value);
  }

  function onChangeEmail(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  function onEmailBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (isValidEmail(email)) {
      checkUniqueEmail(email);
    }
  }

  function onChangePassword(e: React.ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  function onChangeConfirmPassword(e: React.ChangeEvent<HTMLInputElement>) {
    setConfirmPassword(e.target.value);
  }

  function getSubscriptionMessage() {
    const firstSubscription = Profile.state.auth?.user.subscriptions?.[0];
    if (firstSubscription && firstSubscription.ends_at) {
      return `You are still subscribed until ${parseDate(
        firstSubscription.ends_at
      )}`;
    } else {
      return "You still have an active subscription";
    }
  }

  return (
    <>
      {Profile.state.auth && (
        <div
          style={{
            display: "flex",
            background: "green",
            color: "#fff",
            padding: 5,
            maxWidth: 450,
            alignSelf: "center",
          }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              display: "flex",
            }}
          >
            <div>{`You are logged in as ${Profile.state.auth.user.name} (${Profile.state.auth.user.email}).`}</div>
            {Profile.getters.isSubscribed && (
              <div>{getSubscriptionMessage()}</div>
            )}
          </div>
        </div>
      )}
      <Card className={classes.container}>
        <h1>Subscribe to Nuance Bro</h1>
        <div className={classes.selectedPlanContainer}>
          <div className={classes.selectedPlanText}>
            Selected plan:{" "}
            <strong>{`\u00A0 ${props.activePlan.name} ($${props.activePlan.cost}/month)`}</strong>
          </div>
          <FaCheck />
        </div>
        <form onSubmit={handleSubmit}>
          <FormErrors
            errorText={state.formErrorText}
            inputErrors={state.formInputErrors}
          />
          {!Profile.getters.isLoggedIn && (
            <div className={classes.formDetailsContainer}>
              <InputField
                label="Full Name"
                name="name"
                type="text"
                required={true}
                onChange={onChangeName}
                value={name}
                minLength={2}
                maxLength={150}
                placeholder="Jack Dorsey"
              />
              <InputField
                label="Email"
                name="email"
                type="email"
                minLength={2}
                maxLength={100}
                autoComplete={"email"}
                errorText={emailTaken ? "Email taken" : undefined}
                required={true}
                onChange={onChangeEmail}
                onBlur={onEmailBlur}
                value={email}
                placeholder="jackdorsey@twitter.com"
              />
              <InputField
                label="Password"
                name="password"
                type="password"
                autoComplete={"new-password"}
                minLength={2}
                maxLength={100}
                required={true}
                onChange={onChangePassword}
                value={password}
              />
              <InputField
                label="Confirm Password"
                name="confirm_password"
                type="password"
                autoComplete={"new-password"}
                required={true}
                minLength={2}
                maxLength={100}
                onChange={onChangeConfirmPassword}
                value={confirmPassword}
              />
            </div>
          )}

          <InputLabel name="credit_card">Card Number</InputLabel>

          <CardElement
            key={cardErrorKey}
            style={{ base: { color: darkMode.value ? "white" : "#000" } }}
          />

          <div className={classes.acceptTosContainer} onClick={toggleAcceptTOS}>
            <div className={classes.svgContainer}>
              {acceptTOS ? <FaRegCheckSquare /> : <FaRegSquare />}
            </div>
            <div>
              By proceeding I confirm that I have read, understood and accept
              the
              <Link to="/terms"> Terms and Conditions</Link>
            </div>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <Button
              className={classes.subscribeButton}
              disabled={state.loading}
            >
              {!state.loading ? (
                "Subscribe"
              ) : (
                <CircularProgress className={classes.loadingContainer} />
              )}
            </Button>
          </div>
        </form>
        <small>You can unsubscribe at any time.</small>
      </Card>
    </>
  );
}

export default injectStripe(CheckoutForm);
