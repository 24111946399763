import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import useScrollToTop from "hooks/useScrollToTop";
import { Plan } from "typings/types";
import classes from "./join.module.scss";
import PricingTable from "./_components/PricingTable/PricingTable";
import StripeFormContainer from "./_components/StripeForm/Container";
import usePageTitle from "hooks/usePageTitle";

export default function JoinPage() {
  useScrollToTop();
  usePageTitle("Join");
  const [step, setStep] = useState(0);
  const [activePlan, setActivePlan] = useState<null | Plan>(null);

  function back() {
    setStep(state => state - 1);
  }

  function onSelectPlan(plan: Plan) {
    setActivePlan(plan);
    setStep(state => state + 1);
  }

  // const steps = getSteps();
  return (
    <div className={classes.container}>
      {step === 1 && (
        <div className={classes.backArrow} onClick={back}>
          <IoIosArrowBack size={35} /> <span>Select plan</span>
        </div>
      )}
      {step === 0 && (
        <>
          <h1 className={classes.selectPlan}>Select a Plan</h1>
          <PricingTable key={1} onSelectPlan={onSelectPlan} />
          <small>
            Tiers with higher price include all content from the tiers with lower prices. Upon selecting tier with price
            higher than the current one, a new subscription price will be instantly applied.
          </small>
        </>
      )}
      {step === 1 && activePlan && <StripeFormContainer activePlan={activePlan} />}
    </div>
  );
}
