import { Thunk, Action, action, thunk } from "easy-peasy";
import { fetchApi } from "utils/utils";

type Stats = {
  active_subscriptions: number;
  comments: number;
  subscriptions: number;
};

type NewSubscription = {
  x: string;
  y: number;
};

export interface AdminModel {
  stats: Stats | null;
  newSubscriptions: { [key: string]: NewSubscription[] } | null;
  getStats: Thunk<AdminModel, void, any, any, Promise<Stats>>;
  setStats: Action<AdminModel, Stats>;
  getNewSubscriptions: Thunk<
    AdminModel,
    { period: "week" | "month" },
    any,
    any,
    Promise<NewSubscription[]>
  >;
  setNewSubscriptions: Action<AdminModel, { period: "week" | "month"; data: NewSubscription[] }>;
}

const adminModel: AdminModel = {
  stats: null,
  newSubscriptions: null,
  setStats: action((state, payload) => {
    state.stats = payload;
  }),
  setNewSubscriptions: action((state, payload) => {
    if (!state.newSubscriptions) {
      state.newSubscriptions = {
        [payload.period]: payload.data,
      };
    } else {
      state.newSubscriptions[payload.period] = payload.data;
    }
  }),
  getStats: thunk(async (actions, payload, helpers) => {
    const currentStats = helpers.getState().stats;
    if (currentStats) {
      return currentStats;
    }
    const stats = await fetchApi<Stats>("/admin/stats");
    actions.setStats(stats);
    return stats;
  }),
  getNewSubscriptions: thunk(async (actions, payload, helpers) => {
    const newSubs = helpers.getState().newSubscriptions;
    if (newSubs && newSubs[payload.period]) {
      return newSubs[payload.period];
    }
    const newSubscriptionsResponse = await fetchApi<NewSubscription[]>(
      `/admin/new-subscriptions?period=${payload.period}`
    );
    actions.setNewSubscriptions({ period: payload.period, data: newSubscriptionsResponse });
    return newSubscriptionsResponse;
  }),
};

export default adminModel;
