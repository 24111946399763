import React from "react";
import Card from "../Card";
import classes from "./Post.module.scss";
import Skeleton from "components/Skeleton/Skeleton";

export default function PostSkeleton() {
  return (
    <Card variant="nopadding" className={classes.container}>
      <Skeleton height={400} />
      <div className={classes.postDetailsContainer}>
        <div className={classes.smallInfoContainer}>
          <div className={classes.date}>
            <Skeleton width={"200px"} />
          </div>
        </div>
        <div className={classes.titleContainer}>
          <h2>
            <Skeleton width={"60%"} />
          </h2>
        </div>
        <Skeleton count={2} />
      </div>
    </Card>
  );
}
