import { thunk, Action, createStore, Thunk, action, createTypedHooks } from "easy-peasy";
import { Plan } from "typings/types";
import { fetchApi } from "utils/utils";
import youtubePlaylistModel, { YoutubePlaylistModel } from "./youtubePlaylist";
import commonModel, { CommonModel } from "./common";
import adminModel, { AdminModel } from "./admin";

interface PlansModel {
  plans: Plan[];
  setPlans: Action<PlansModel, Plan[]>;
  getPlans: Thunk<PlansModel, void, any, any, Promise<Plan[]>>;
  /** Gets plans based on user (if testing, only gets testing plans) */
  getUserPlans: Thunk<PlansModel, void, any, any, Promise<Plan[]>>;
}

const plansModel: PlansModel = {
  plans: [],
  setPlans: action((state, payload) => {
    state.plans = payload;
  }),
  getPlans: thunk(async (actions, payload, helpers) => {
    const currentPlans = helpers.getState().plans;
    if (currentPlans.length > 0) {
      return currentPlans;
    }
    const isTesting = window.location.search.includes("test");

    const plans = await fetchApi<Plan[]>(`/pricing-plans${isTesting ? "?test=true" : ""}`);

    actions.setPlans(plans);
    return plans;
  }),

  getUserPlans: thunk(async (actions, payload, helpers) => {
    const currentPlans = helpers.getState().plans;
    if (currentPlans.length > 0) {
      return currentPlans;
    }
    const plans = await fetchApi<Plan[]>(`/user/plans`);
    actions.setPlans(plans);
    return plans;
  }),
};

const store = createStore({
  admin: adminModel,
  plans: plansModel,
  common: commonModel,
  youtubePlaylist: youtubePlaylistModel,
});

interface StoreModel {
  admin: AdminModel;
  plans: PlansModel;
  common: CommonModel;
  youtubePlaylist: YoutubePlaylistModel;
}

const typedHooks = createTypedHooks<StoreModel>();

// 👇export the typed hooks
export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export default store;
