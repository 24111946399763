import React from "react";
import Button from "./Button";
import classes from "./ErrorMessage.module.scss";

export default function ErrorMessage(props: {
  title: string;
  message?: string;
  button?: {
    title: string;
    onClick: VoidFunction;
  };
}) {
  return (
    <div className={classes.container}>
      <h1>{props.title}</h1>
      {props.message && <p className={classes.message}>{props.message}</p>}
      {props.button && (
        <Button
          onClick={props.button.onClick}
          fitContent={true}
          className={classes.reloadButton}
        >
          {props.button.title}
        </Button>
      )}
    </div>
  );
}

ErrorMessage.defaultProps = {
  displayReloadButton: true
};
