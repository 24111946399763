import React from "react";
import classes from "./Extended.module.scss";
import * as Modal from "./Modal";

interface IExtendedModalProps {
  actions?: React.ReactNode;
  title: string | React.ReactNode;
  sticky?: boolean;
  children: React.ReactNode;
  isOpen?: boolean;
  disableOverlayInteractions?: boolean;
  onClose?(): void;
  bodyClass?: string;
}

function ExtendedModal({
  isOpen,
  disableOverlayInteractions,
  actions,
  title,
  children,
  sticky,
  onClose,
  bodyClass,
}: IExtendedModalProps) {
  return (
    <Modal.Base
      isOpen={isOpen}
      onClose={onClose}
      sticky={sticky}
      disableOverlayInteractions={disableOverlayInteractions}
      bodyClass={bodyClass}
    >
      <div className={classes.modalBackground}>
        <TitleBar onClose={onClose} title={title} sticky={sticky} />
        <div className={classes.contentContainer}>{children}</div>
        {actions && <div className={classes.buttonsContainer}>{actions}</div>}
      </div>
    </Modal.Base>
  );
}

interface TitleBarProps {
  title: string | React.ReactNode;
  sticky?: boolean;
  onClose?: () => void;
}

function TitleBar({ title, onClose, sticky }: TitleBarProps) {
  return (
    <div className={classes.titleBar}>
      {typeof title === "string" ? <h2 className={classes.modalTitle}>{title}</h2> : title}
      {!sticky && (
        <button className={classes.closeButton} aria-label={"Close"} onClick={onClose}>
          ×
        </button>
      )}
    </div>
  );
}

export default ExtendedModal;
