import React, { useContext } from "react";
import { ProfileContext } from "components/ProfileContext";
import Card from "components/ui/Card";
import SubscribeButton from "components/ui/SubscribeButton";

export default function SupportNuanceBro() {
  const Profile = useContext(ProfileContext);

  if (!Profile || Profile.getters.isLoggedIn) {
    return null;
  }

  return (
    <Card style={{ marginBottom: "1rem" }}>
      <h2 style={{ marginBottom: 10 }}>Support Nuance Bro</h2>
      <SubscribeButton />
    </Card>
  );
}
