import React from "react";
import useDarkMode from "use-dark-mode";
import classes from "./DarkModeToggle.module.scss";
import classnames from "classnames";
import dark from "./images/dark.png";
import light from "./images/light.png";

const DarkModeToggle = () => {
  const darkMode = useDarkMode(false);

  return (
    <div className={classes.darkModeToggle} onClick={darkMode.toggle}>
      <div className={classes.toggleTrack}>
        <div className={classes.toggleTrackCheck}>
          <img alt="dark toggle" src={dark} />
        </div>
        <div className={classes.toggleTrackX}>
          <img alt="light toggle" src={light} />
        </div>
      </div>
      <div
        className={classnames({
          [classes.toggleThumb]: true,
          [classes.checked]: darkMode.value,
        })}
      />
    </div>
  );
};

export default DarkModeToggle;
