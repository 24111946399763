import classnames from "classnames";
import React, { useState } from "react";
import InputLabel from "../InputLabel";
import classes from "./InputField.module.scss";

type Props = {
  use?: "input" | "textarea";
  containerClassName?: string;
  label?: string;
  errorText?: string;
} & React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>;

export default function InputField(props: Props) {
  const [focused, setFocused] = useState(false);
  const { containerClassName, className, label, errorText, ...rest } = props;

  function onInputFocus(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (props.onFocus) {
      props.onFocus(e);
    }
    setFocused(true);
  }

  function onInputBlur(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (props.onBlur) {
      props.onBlur(e);
    }
    setFocused(false);
  }

  const T: any = props.use || "input";

  return (
    <div className={classnames(classes.container, containerClassName)}>
      {label && (
        <InputLabel name={rest.name} className={focused ? classes.focused : ""}>
          {label}
        </InputLabel>
      )}
      <T
        {...rest}
        className={classnames(
          classes.input,
          {
            [classes.error]: Boolean(errorText),
          },
          className,
        )}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
      />
      {errorText && <small className={classes.error}>{errorText}</small>}
    </div>
  );
}
