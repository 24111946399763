import classnames from "classnames";
import React from "react";
import styles from "./styles.module.css";

const CircularProgress = (props: { className?: string }) => (
  <div className={classnames(styles.circularProgressContainer, props.className)}>
    <svg viewBox="22 22 44 44">
      <circle strokeWidth="5" fill="none" r="20.2" cy="44" cx="44" className={styles.circularProgressCircle} />
    </svg>
  </div>
);

export default CircularProgress;
