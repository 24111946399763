import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";
import classnames from "classnames";

interface Props {
  disableOverlayInteractions?: boolean;
  sticky?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  zIndex?: number;
  children: React.ReactNode;
  bodyClass?: string;
}

export function Base(props: Props) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function onKeyPress(ev: KeyboardEvent) {
      // 27 = escape
      if (ev.keyCode === 27 && props.onClose) {
        props.onClose();
      }
    }

    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [props]);

  useEffect(() => {
    if (props.isOpen && modalRef.current) {
      modalRef.current.focus();
    }
  }, [props.isOpen]);

  function onOverlayClick(e: React.MouseEvent<HTMLDivElement>) {
    if (props.onClose && !props.disableOverlayInteractions && overlayRef.current === e.target) {
      props.onClose();
    }
  }

  if (!props.isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div ref={modalRef} tabIndex={-1} style={{ position: "fixed", zIndex: 999 }}>
      <div
        className={styles.modalOverlay}
        ref={overlayRef}
        onClick={!props.sticky ? onOverlayClick : undefined}
        style={{
          pointerEvents: !props.isOpen ? "none" : "initial",
          position: "fixed",
          zIndex: props.zIndex || 999,
        }}
      >
        <div
          className={classnames(styles.modalBody, props.bodyClass)}
          aria-modal={true}
          aria-haspopup={true}
          style={{
            zIndex: props.zIndex || 999,
          }}
        >
          {props.children}
        </div>
      </div>
    </div>,
    document.querySelector("body") as Element,
  );
}
