import React, { Suspense, useContext } from "react";
import { MdPerson } from "react-icons/md";
import ErrorBoundary from "components/ErrorBoundary";
import PageLoader from "components/PageLoader";
import { ProfileContext } from "components/ProfileContext";
import { SidebarProvider } from "components/SidebarContext";
import Footer from "components/ui/Footer";
import SideBar from "components/ui/SideBar";
import TopBar from "components/ui/TopBar";
import classes from "./Default.module.scss";
import { Outlet } from "react-router-dom";

const items = [
  {
    link: "/members/user/profile",
    icon: MdPerson,
    text: "Profile",
  },
];

export default function DefaultLayout(props: { children?: React.ReactNode }) {
  const Profile = useContext(ProfileContext);

  return (
    <>
      <SidebarProvider>
        <TopBar />
        {Profile && Profile.getters.isLoggedIn && <SideBar items={items} />}
      </SidebarProvider>

      <div className={classes.container}>
        <ErrorBoundary key={1}>
          <Suspense fallback={<PageLoader />}>{props.children || <Outlet />}</Suspense>
        </ErrorBoundary>
      </div>

      <Footer />
    </>
  );
}
