// import RouterProvider from "components/Router";
import { Router, Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import { history } from "./utils/routerHistory";
import { toast, ToastContainer } from "react-toastify";
import ProfileProvider from "components/ProfileContext";
import NotFound from "./components/NotFound";
import DefaultLayout from "./layouts/Default";
import MembersLayout from "./layouts/Members";
import IndexPage from "./pages/index";
// /admin
import AdminLayout from "layouts/Admin";
import PageLoader from "components/PageLoader";
import { StoreProvider } from "easy-peasy";
import store from "./store";

import LoginPage from "./pages/login";
import JoinPage from "./pages/join/join";
import AboutPage from "./pages/about";

const ForgotPasswordPage = React.lazy(() => import("./pages/forgot-password"));
const PasswordResetPage = React.lazy(() => import("./pages/password-reset"));
const MembersPage = React.lazy(() => import("./pages/members/members"));
const TermsPage = React.lazy(() => import("./pages/terms"));
const PostPage = React.lazy(() => import("./pages/members/post"));
const ProfilePage = React.lazy(() => import("./pages/members/user/profile"));
const ContactPage = React.lazy(() => import("./pages/contact"));
// /admin
const AdminIndexPage = React.lazy(() => import("./pages/admin/index"));
const PostsPage = React.lazy(() => import("./pages/admin/posts"));
const UserAccountsPage = React.lazy(() => import("./pages/admin/users"));
const PlansPage = React.lazy(() => import("./pages/admin/plans"));

function App() {
  return (
    <Router history={history}>
      <Suspense fallback={<PageLoader />}>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} />
        <StoreProvider store={store}>
          <ProfileProvider>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <DefaultLayout>
                    <IndexPage />
                  </DefaultLayout>
                }
              />
              <Route path="/" element={<DefaultLayout />}>
                <Route path="login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/password-reset" element={<PasswordResetPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/join" element={<JoinPage />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="/members" element={<MembersLayout />}>
                <Route exact path="/" element={<MembersPage />} />
                <Route path="user/profile" element={<ProfilePage />} />
                <Route path="post/:slug" element={<PostPage />} />
              </Route>

              <Route path="/admin" element={<AdminLayout />}>
                <Route exact path="/" element={<AdminIndexPage />} />
                <Route path="users" element={<UserAccountsPage />} />
                <Route path="posts" element={<PostsPage />} />
                <Route path="plans" element={<PlansPage />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </ProfileProvider>
        </StoreProvider>
      </Suspense>
    </Router>
  );
}

export default App;
